import { SvgPathProps, withMuiSvg } from "../../withMuiSvg";

const Icon = ({ contractColor, paletteColor }: SvgPathProps) => {
  return (
    <>
      <mask
        id="mask0_1215_112665"
        style={{ maskType: "luminance" }}
        width="164"
        height="170"
        x="39"
        y="20"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M88.596 20.308a17.8 17.8 0 0116.997 6.128l92.242 105.518c9.52 10.942 4.924 27.83-8.28 30.529L61.897 188.707c-13.167 2.699-25.02-10.942-21.373-24.474L76.159 32.527a16.376 16.376 0 0112.437-12.219z"
        ></path>
      </mask>
      <g mask="url(#mask0_1215_112665)">
        <path
          fill="url(#paint0_linear_1215_112665)"
          d="M88.596 20.308a17.8 17.8 0 0116.997 6.128l92.242 105.518c9.52 10.942 4.924 27.83-8.28 30.529L61.897 188.707c-13.167 2.699-25.02-10.942-21.373-24.474L76.159 32.527a16.376 16.376 0 0112.437-12.219z"
        ></path>
      </g>
      <path
        fill="url(#paint1_linear_1215_112665)"
        d="M93.995 21.985a17.523 17.523 0 0116.731 6.031l90.801 103.87c9.371 10.771 4.847 27.395-8.114 30.052L67.462 187.753c-12.926 2.657-24.63-10.771-21.004-24.092L81.5 34.012a16.229 16.229 0 0112.495-12.027z"
      ></path>
      <g opacity="0.8">
        <path
          fill="url(#paint2_linear_1215_112665)"
          d="M122.689 137.529a7.79 7.79 0 018.384 3.051c.608.866 1.251 2.884 1.251 2.884s.625 2.006.606 3.056a7.336 7.336 0 01-2.624 5.493 7.334 7.334 0 01-2.757 1.449 7.905 7.905 0 01-8.291-3.189c-.6-.864-1.239-2.868-1.239-2.868s-.594-1.974-.571-3.004a7.34 7.34 0 015.241-6.872z"
        ></path>
        <path
          fill="url(#paint3_linear_1215_112665)"
          d="M123.712 125.897l-8.2 2.335-20.53-61.72 11.647-3.169 17.083 62.554z"
        ></path>
      </g>
      <g fill="#fff" opacity="0.9">
        <path d="M122.914 137.018a7.798 7.798 0 016.007 1.363 7.789 7.789 0 012.116 2.326c.54.911 1.024 2.973 1.024 2.973s.468 2.047.368 3.093a7.336 7.336 0 01-3.04 5.274 7.346 7.346 0 01-2.861 1.231 7.891 7.891 0 01-5.94-1.489 7.893 7.893 0 01-2.08-2.332c-.531-.907-1.014-2.954-1.014-2.954s-.439-2.014-.337-3.039a7.355 7.355 0 012.968-5.194 7.346 7.346 0 012.789-1.252zM124.833 125.5l-8.356 1.694-15.696-63.123 11.857-2.259 12.195 63.688z"></path>
      </g>
      <g filter="url(#filter0_f_1215_112665)">
        <ellipse
          cx="120.202"
          cy="206.906"
          fill="#E9E7F6"
          rx="98.202"
          ry="13.094"
        ></ellipse>
      </g>
      <defs>
        <filter
          id="filter0_f_1215_112665"
          width="204.403"
          height="34.187"
          x="18"
          y="189.813"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_1215_112665"
            stdDeviation="2"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_1215_112665"
          x1="25.254"
          x2="262.791"
          y1="45.213"
          y2="147.795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F5F"></stop>
          <stop offset="1" stopColor="#FEC84B"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1215_112665"
          x1="41.05"
          x2="165.291"
          y1="-184.282"
          y2="188.443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F5F"></stop>
          <stop offset="1" stopColor="#FEC84B"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1215_112665"
          x1="119.016"
          x2="133.636"
          y1="131.782"
          y2="144.774"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F5F"></stop>
          <stop offset="1" stopColor="#FEC84B"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1215_112665"
          x1="97.007"
          x2="135.996"
          y1="40.682"
          y2="53.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F5F"></stop>
          <stop offset="1" stopColor="#FEC84B"></stop>
        </linearGradient>
      </defs>
    </>
  );
};

Icon.displayName = "EposWarningPopupIcon";

const EposWarningPopupIcon = withMuiSvg(Icon, {
  viewBox: "0 0 240 240",
  width: 240,
  height: 240,
  fill: 'none',
  fontSize: '15rem',
  stroke: 'none',
});

export default EposWarningPopupIcon;
