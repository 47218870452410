"use client"

import AnnouncementView from "@/features/App/views/announcement.view";
import { usePathname } from "next/navigation";

export default function Custom404() {
  return (
    <AnnouncementView status={404}>
      Not Found
    </AnnouncementView>
  )
}