import EposWarningPopupIcon from '@/public/icons/common/popup-icon/Warning.popup.icon'
import { IAnnouncementSectionrops } from './AnnouncementSection.props'
import { AnoucementTitleIcon, AnoucementTitleText, AnoucementWrapper } from './AnnouncementSection.styles'

const AnnouncementSection = ({ children, IconComponent }: IAnnouncementSectionrops) => {
  return (
    <AnoucementWrapper size='sm'>
      <AnoucementTitleIcon>
        {IconComponent ? <IconComponent/> : <EposWarningPopupIcon/>}
      </AnoucementTitleIcon>
      <AnoucementTitleText>
        {children}
      </AnoucementTitleText>
    </AnoucementWrapper>
  )
}

export default AnnouncementSection